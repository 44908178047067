// 动态资讯 新闻列表页
<template>
  <div class="news-display-wrapper">
    <Bigimg
      title="动态资讯"
      subTitle="把握行业动态、市场资讯，比他人更快速地了解和掌握专业信息"
      :imgUrl="require('../../assets/images/dtzx.jpg')"
    ></Bigimg>
    <div class="news-display">
      <newsItem :news="news" v-for="(news, index) in newsList" :key="index">
      </newsItem>
    </div>
    <div class="pagination-wrapper">
      <el-pagination
        prev-text="上一页"
        next-text="下一页"
        class="mb-6"
        layout="prev, pager, next"
        :total="total"
        :page-size="limit"
        :current-page="page"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getNewsList } from "@/service/allapi";
import newsItem from "@/components/news-item.vue";
import Bigimg from "@/components/bigimg.vue";
export default {
  components: { newsItem, Bigimg },
  data() {
    return {
      newsList: [],
      total: 0,
      page: 1,
      limit: 4
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 分页功能
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      getNewsList({ pageNum: this.page, pageSize: this.limit }).then(res => {
        this.newsList = res.data.list;
        this.total = res.data.total;
        let page = document.querySelector("#app");
        if (page.scrollTop > 0) {
          page.scrollTop = 0;
        }
      });
    },
    goDetails(news) {
      // console.log("news", news);
    }
  }
};
</script>
<style scoped lang="scss">
.mb-6 {
  margin-bottom: 60px;
}
.news-display {
  padding: 80px 0 0;
}
.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/.el-pagination {
    padding: 0;
    border-top: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
    border-radius: 4px;

    .btn-prev,
    .btn-next,
    .number,
    .more {
      border-bottom: 1px solid #cccccc;
      border-right: 1px solid #cccccc;
    }
    .active {
      color: #ffffff;
      background: #007bb9;
    }
  }
}
</style>
