<template>
  <div class="news-item wrap-v1">
    <div class="bg" :style="{ backgroundImage: `url(${news.poster})` }"></div>
    <div class="time" v-if="news.addTime">
      <div class="day">{{ news.addTime | getDay }}</div>
      <div class="month">{{ news.addTime | getMonth }}</div>
    </div>
    <div class="info">
      <div class="t1 text-nowrap">{{ news.title }}</div>
      <div class="t2 text-nowrap2">{{ news.subtitle }}</div>
      <button @click="goDetails">查看更多</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    news: { type: Object, required: true }
  },
  created() {
    // console.log("news", this.news);
  },
  methods: {
    goDetails() {
      this.$router.push(`/news/details/${this.news.id}`);
    }
  }
};
</script>
<style scoped lang="scss">
.news-item {
  position: relative;
  margin-bottom: 190px;
  .bg {
    width: 100%;
    height: 450px;
    background: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .time {
    position: absolute;
    z-index: 10;
    width: 120px;
    height: 120px;
    background: #007bb9;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: #ffffff;
    .day {
      margin-top: 10px;
      font-size: 50px;
      line-height: 70px;
    }
    .month {
      font-size: 22px;
      line-height: 30px;
    }
  }
  .info {
    box-sizing: border-box;
    position: absolute;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1080px;
    width: 90%;
    height: 240px;
    background: #ffffff;
    box-shadow: 0px 11px 20px 0px rgba(0, 0, 0, 0.06);
    padding: 40px 50px 30px;
    text-align: left;
    .t1 {
      font-size: 26px;
      color: #333333;
      line-height: 37px;
    }
    .t2 {
      margin-top: 20px;
      font-size: 20px;
      color: #9c9c9c;
      line-height: 28px;
    }
    button {
      margin-top: 20px;
      padding: 8px 30px;
      width: 125px;
      height: 38px;
      background: #f7f7f7;
      font-size: 16px;
      color: #9c9c9c;
      line-height: 22px;
      cursor: pointer;
      &:hover{
        background: #007bb9;
        color: #fff;
      }
    }
  }
}
</style>
